

$(document).ready(function() {

    var url = document.location.toString();
    if ( url.match('#') ) {
        $('#'+url.split('#')[1]).addClass('in');
    }

    $('.gallery').slick({
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        swipe: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 1000,
        responsive: [
        {
          breakpoint: 993,
          settings: {
            swipe: true,
            autoplay: false,
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            swipe: true,
            autoplay: false,
            slidesToShow: 2,
          }
        }
      ]
    });

    $("input[name='owner_insured_same']").change(function(){
      if($("input[name='owner_insured_same']:checked").val() == 'no'){
          $("#owner-data").slideDown();
      }else{
          $("#owner-data").slideUp();
      }
    });

    $("input[name='driver_insured_same']").change(function(){
      if($("input[name='driver_insured_same']:checked").val() == 'no'){
          $("#driver-data").slideDown();
      }else{
          $("#driver-data").slideUp();
      }
    });
    
    $("input[name='eventual_driver']").change(function(){
      if($("input[name='eventual_driver']:checked").val() == 'yes'){
          $("#eventual-driver-data").slideDown();
      }else{
          $("#eventual-driver-data").slideUp();
      }
    });

    $("input[name='insurance_type']").change(function(){
      if($("input[name='insurance_type']:checked").val() == 'renew'){
          $("#renew-data").slideDown();
      }else{
          $("#renew-data").slideUp();
      }
    });


    // process the form
    $('form').submit(function(event) {

        // get the form data
        // there are many ways to get this data using jQuery (you can use the class or id also)

        $("#contact-result").slideUp();
        $("#contact-loader").fadeIn();

        name = $('input[name=name]').val();
        email = $('input[name=email]').val();
        phone = $('input[name=phone]').val();
        message = $('textarea[name=message]').val();
        token = $("input[name=_token]").val();

        // process the form
        $.ajax({
          headers: {'X-CSRF-TOKEN': token},
            type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
            url         : baseUrl + 'contact', // the url where we want to POST
            data        : {_token: token, name: name, email: email, phone: phone, message: message},
            dataType    : 'json', // what type of data do we expect back from the server
            encode          : true,
            success : function(data){
              $("#contact-loader").slideUp();
              $("#contact-result").text(data.contact.message)
              if(data.contact.result == "1"){
                $("#contact-result").removeClass("alert-danger");
                $("#contact-result").addClass("alert-success");
              }else{
                $("#contact-result").removeClass("alert-success");
                $("#contact-result").addClass("alert-danger");
              }
              $('#contact form').trigger("reset");
              $("#contact-result").fadeIn();
            },
            error : function(){
              $("#contact-loader").slideUp();
              $("#contact-result").text("Erro ao enviar mensagem. Tente novamente mais tarde.");
              $("#contact-result").removeClass("alert-success");
              $("#contact-result").addClass("alert-danger");
              $("#contact-result").fadeIn();
            }
          })

        event.preventDefault();
      });

});